import getUser from 'state/User/selectors';
import HeroSection from './HeroSection';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { fetchGenrePreferences } from 'state/Genres/actions';
import { flowRight } from 'lodash-es';
import {
  getLiveRadioLink,
  getNewsLink,
  getPlaylistsLink,
  getPodcastsLink,
  getTheAppLink,
} from 'state/Links/selectors';
import { getSelectedGenres } from 'state/Genres/selectors';
import { localize } from 'redux-i18n';
import { State } from 'state/buildInitialState';
import type { User } from 'state/User/types';

export type Props = {
  appLink: string;
  liveLink: string;
  newsLink: string;
  playlistLink: string;
  podcastLink: string;
  selectedGenres: { [id: string]: boolean | undefined } | undefined;
  user: User;
};

export default flowRight(
  localize('translate'),
  connect(
    createStructuredSelector<State, Props>({
      appLink: getTheAppLink,
      liveLink: getLiveRadioLink,
      newsLink: getNewsLink,
      playlistLink: getPlaylistsLink,
      podcastLink: getPodcastsLink,
      selectedGenres: getSelectedGenres,
      user: getUser,
    }),
    {
      fetchGenrePreferences,
    },
  ),
)(HeroSection);
