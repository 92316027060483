import theme from 'styles/themes/default';

type Props = {
  'data-test'?: string;
  className?: string;
};

function Apple(props: Props) {
  return (
    <svg {...props} fill="none" height="29" viewBox="0 0 24 29" width="24">
      <path
        d="M21.6843 25.3995C20.47 27.1674 19.2139 28.9244 17.2308 28.9612C15.279 28.998 14.6533 27.8103 12.4262 27.8103C10.1968 27.8103 9.49935 28.9244 7.65658 28.998C5.74205 29.0694 4.28626 27.0887 3.06197 25.3288C0.560779 21.7304 -1.34823 15.1544 1.21918 10.7165C2.49056 8.51257 4.76872 7.11812 7.23745 7.0813C9.11762 7.04685 10.8938 8.34328 12.0422 8.34328C13.1923 8.34328 15.3481 6.78677 17.6145 7.01507C18.5632 7.05409 21.2259 7.39565 22.9358 9.88672C22.8007 9.97515 19.7577 11.7377 19.7948 15.4097C19.832 19.7961 23.6556 21.2541 23.7 21.274C23.6654 21.3771 23.0888 23.3555 21.6843 25.3995ZM13.3577 2.19655C14.4073 0.972012 16.1808 0.0612002 17.6445 0C17.8315 1.70095 17.1441 3.40424 16.129 4.63161C15.1118 5.85678 13.4467 6.81116 11.8125 6.68373C11.5905 5.01959 12.4141 3.28403 13.3577 2.19655Z"
        fill={theme.colors.white.primary}
      />
    </svg>
  );
}

export default Apple;
