import AppleIcon from 'styles/icons/AppleIcon';
import Body1 from 'primitives/Typography/BodyCopy/Body1';
import Body2 from 'primitives/Typography/BodyCopy/Body2';
import ButtonsContainer from './primitives/ButtonsContainer';
import Caption from 'primitives/Typography/BodyCopy/Caption';
import DevicesButton from './primitives/DevicesButton';
import DevicesButtonIcon from './primitives/DevicesButtonIcon';
import DevicesButtonText from './primitives/DevicesButtonText';
import DevicesLeft from './primitives/DevicesLeft';
import DevicesRight from './primitives/DevicesRight';
import DevicesWrapper from './primitives/DevicesWrapper';
import GooglePlay from 'styles/icons/GooglePlay';
import H2 from 'primitives/Typography/Headings/H2';
import IconWrapper from './primitives/IconWrapper';
import Link from '../primitives/Link';
import LinkIcon from 'views/Welcome/primitives/LinkIcon';
import theme from 'styles/themes/default';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import WelcomeSection from 'components/WelcomeSection';

const APPLE_LINK =
  'https://itunes.apple.com/us/app/iheartradio/id290638154?mt=8';
const GOOGLE_PLAY_LINK =
  'https://play.google.com/store/apps/details?id=com.clearchannel.iheartradio.controller&hl=en_US';

type Props = {
  ihrAppsLink: string;
};

function DevicesSection({
  ihrAppsLink = 'https://www.iheart.com/apps/',
}: Props) {
  const translate = useTranslate();

  return (
    <WelcomeSection background={theme.colors.gray['200']}>
      <DevicesWrapper>
        <DevicesLeft>
          <H2>{translate('Bring iHeart with you anywhere')}</H2>
          <Body2>
            {translate(
              'The free iHeart app is available on over 200 platforms, including Web, iOS, Android, Alexa, automobiles and more.',
            )}
          </Body2>
          <ButtonsContainer>
            <DevicesButton to={APPLE_LINK}>
              <DevicesButtonIcon>
                <AppleIcon />
              </DevicesButtonIcon>
              <DevicesButtonText>
                <Caption>{translate('Download on the')}</Caption>
                <Body1>App Store</Body1>
              </DevicesButtonText>
            </DevicesButton>
            <DevicesButton to={GOOGLE_PLAY_LINK}>
              <DevicesButtonIcon>
                <GooglePlay />
              </DevicesButtonIcon>
              <DevicesButtonText>
                <Caption>{translate('Download on')}</Caption>
                <Body1>Google Play</Body1>
              </DevicesButtonText>
            </DevicesButton>
          </ButtonsContainer>
          <Link target="_blank" to={ihrAppsLink} underline={false}>
            {translate('View All Apps')}
            <LinkIcon name="caret-right" />
          </Link>
        </DevicesLeft>
        <DevicesRight>
          <IconWrapper />
        </DevicesRight>
      </DevicesWrapper>
    </WelcomeSection>
  );
}

export default DevicesSection;
