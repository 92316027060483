import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const LinksWrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.colors.white.primary,
  borderRadius: '0.6rem',
  boxShadow: '0 0.8rem 2.4rem rgba(0, 0, 0, 0.12)',
  display: 'flex',
  flexWrap: 'wrap',
  height: '12rem',
  justifyContent: 'space-between',
  position: 'absolute',
  transform: 'translateY(-3rem)',
  width: '100%',

  [mediaQueryBuilder(theme.mediaQueries.max.width['1280'])]: {
    marginBottom: '-8rem',
    position: 'relative',
  },

  [mediaQueryBuilder([
    theme.mediaQueries.max.width['980'],
    theme.mediaQueries.min.width['768'],
  ])]: {
    transform: 'translateY(-1.5rem)',
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    transform: 'translateY(-6rem)',
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['599'])]: {
    borderRadius: 0,
    height: 'auto',
    marginBottom: 0,
    marginTop: '6rem',
    transform: 'translateY(0)',
  },
}));

export default LinksWrapper;
