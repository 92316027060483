import styled from '@emotion/styled';

const DevicesButtonText = styled('div')({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '1.9rem',
  width: '100%',
});

export default DevicesButtonText;
