import NavRightComponent from 'styles/icons/NavRight';
import styled from '@emotion/styled';

const NavRight = styled(NavRightComponent)({
  height: '100%',
  opacity: 0,
  position: 'absolute',
  right: 0,
  transform: 'translate(4rem, 0.2rem)',
  transition: 'all 300ms ease-in-out',
});

export default NavRight;
