import H4 from './H4';
import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import NavLink from 'primitives/Typography/Links/NavLink';
import NavRight from './NavRight';
import styled from '@emotion/styled';

type Props = {
  hoverColor: string;
};

const Link = styled(NavLink)<Props>(({ hoverColor, theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  width: '25%',

  '&:hover': {
    [H4.toString()]: {
      color: hoverColor,
    },

    [NavRight.toString()]: {
      opacity: 1,
      transform: 'translate(2.5rem, 0.2rem)',
    },
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['599'])]: {
    border: `1px solid ${theme.colors.gray['200']}`,
    height: '10.6rem',
    paddingBottom: '2rem',
    paddingTop: '2rem',
    width: '50%',
  },
}));

export default Link;
