import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

type Props = {
  background?: string;
  bottomDivider?: boolean;
  className?: string;
};

const WelcomeWrapper = styled('div')<Props>(
  ({ background, bottomDivider = false, theme }) => ({
    background: background || theme.colors.gray['100'],
    borderBottom:
      bottomDivider ? `1px solid ${theme.colors.gray['200']}` : 'none',
    overflow: 'hidden',
    padding: '4rem 6rem',
    position: 'relative',

    [mediaQueryBuilder(theme.mediaQueries.max.width['980'])]: {
      padding: '4rem 1rem',
    },
  }),
);

export default WelcomeWrapper;
