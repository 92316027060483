import BR from 'primitives/Layout/BR';
import CatalogImage from 'components/MediaServerImage/CatalogImage';
import H1 from 'primitives/Typography/Headings/H1';
import Link from '../primitives/Link';
import LinkIcon from 'views/Welcome/primitives/LinkIcon';
import NavLink from 'components/NavLink';
import P from 'primitives/Typography/BodyCopy/P';
import Tile from 'components/Tile/Tile';
import Tiles from 'components/Tiles/Tiles';
import WelcomeSection from 'components/WelcomeSection';
import { Component } from 'react';
import { IGetTranslateFunctionResponse } from 'redux-i18n';
import { Podcast } from 'state/Podcast/types';

type Props = {
  getPodcasts: () => void;
  horizontalScroll?: boolean;
  podcastLink: string;
  podcasts: Array<Podcast>;
  singleRow?: boolean;
  tilesInRow: number;
  translate: IGetTranslateFunctionResponse;
};

class PodcastSection extends Component<Props> {
  static defaultProps = {
    podcastLink: '/podcast/',
  };

  componentDidMount() {
    const { getPodcasts, podcasts } = this.props;
    if (!podcasts.length) {
      getPodcasts();
    }
  }

  render() {
    const {
      horizontalScroll,
      podcasts = [],
      podcastLink,
      singleRow,
      tilesInRow,
      translate,
    } = this.props;
    const limit = singleRow ? tilesInRow : Infinity;
    const podcastTiles = podcasts?.slice(0, limit) ?? [];

    return (
      <WelcomeSection bottomDivider dataTest="welcome-page-podcast-section">
        {/* H1 for styles, h2 for sequential headers in DOM */}
        <H1 as="h2">{translate('Podcasts')}</H1>
        <P data-test="welcome-page-podcast-text">
          {translate(
            'Popular and exclusive podcasts in a variety of genres and topics.',
          )}
        </P>
        <BR />
        <Tiles horizontalScroll={horizontalScroll} tilesInRow={tilesInRow}>
          {podcastTiles.map((podcast, i) => {
            const { imgUrl, title, url, seedType, seedId } = podcast;
            const imgWidth = 240;
            return (
              <Tile
                horizontalScroll={horizontalScroll}
                key={seedId}
                singleRow={singleRow}
                tilePosition={i}
                tilesInRow={tilesInRow}
                title={title}
                url={url}
              >
                <NavLink
                  css={{ display: 'block', position: 'relative' }}
                  itemSelected={{
                    id: seedId,
                    location: 'home|podcasts|tile',
                    name: title,
                    type: seedType,
                  }}
                  to={url}
                >
                  <CatalogImage
                    alt={title}
                    aspectRatio={1}
                    height={imgWidth}
                    id={seedId}
                    src={imgUrl}
                    type={seedType}
                    width={imgWidth}
                  />
                </NavLink>
              </Tile>
            );
          })}
        </Tiles>
        <Link floatRight to={podcastLink} underline={false}>
          {translate('View More Podcasts')}
          <LinkIcon name="caret-right" />
        </Link>
      </WelcomeSection>
    );
  }
}

export default PodcastSection;
