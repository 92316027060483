import SectionWrapper from './primitives/SectionWrapper';
import Wrapper from './primitives/WelcomeWrapper';
import { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
  background?: string;
  bottomDivider?: boolean;
  className?: string;
  dataTest?: string;
}>;

function WelcomeSection({
  background = '',
  bottomDivider = false,
  children,
  className = '',
  dataTest,
}: Props) {
  return (
    <Wrapper
      background={background}
      bottomDivider={bottomDivider}
      className={className}
      data-test={`welcome-section-${dataTest}-wrapper`}
    >
      <SectionWrapper data-test={`welcome-section-${dataTest}-section-wrapper`}>
        {children}
      </SectionWrapper>
    </Wrapper>
  );
}

export default WelcomeSection;
