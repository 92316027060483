import loadable from '@loadable/component';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Event } from 'state/Events/types';
import { getHomepageEventsSection } from 'state/Features/selectors';
import { getReceivedEvents } from 'state/Events/selectors';
import { State } from 'state/types';

const EventsSection = loadable(() => import('./EventsSection'));

export default connect(
  createStructuredSelector<
    State,
    {
      events: Array<Event>;
      homepageEventsSection: boolean;
    }
  >({
    events: getReceivedEvents,
    homepageEventsSection: getHomepageEventsSection,
  }),
)(EventsSection);
