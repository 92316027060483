import styled from '@emotion/styled';

const DevicesButtonIcon = styled('div')(({ theme }) => ({
  svg: {
    fill: theme.colors.white.primary,
    maxHeight: '2.9rem',
    maxWidth: '2.6rem',
  },
}));

export default DevicesButtonIcon;
