import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import NavLink from 'components/NavLink';
import styled from '@emotion/styled';

type Props = {
  to: string;
};

const CardContainer = styled(NavLink)<Props>(({ theme }) => ({
  '&:hover': {
    boxShadow: '0px 5px 8px rgba(0, 0, 0, 0.12)',
    transform: 'translate(0,-5px)',
    transition: '0.2s ease-in-out',
  },
  borderRadius: '5px',
  boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.15)',
  float: 'left',
  height: '28rem',
  margin: '0 3% 3rem 0',
  overflow: 'hidden',
  position: 'relative',
  width: '47%',
  [mediaQueryBuilder(theme.mediaQueries.max.width['1160'])]: {
    ':nth-of-type(2)': {
      margin: '0 0 3rem 0',
    },
    margin: '0 2% 3rem 0',
    width: '48%',
  },
  [mediaQueryBuilder(theme.mediaQueries.max.width['599'])]: {
    height: '30rem',
    margin: '0 0 3rem 0',
    width: '100%',
  },
}));

export default CardContainer;
