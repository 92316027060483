import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import PageWrapper from 'primitives/Layout/PageWrapper';
import styled from '@emotion/styled';

const SectionWrapper = styled(PageWrapper)(({ theme }) => ({
  backgroundColor: 'transparent',
  maxWidth: '112.2rem',
  padding: 0,

  [mediaQueryBuilder([
    theme.mediaQueries.min.width['1024'],
    theme.mediaQueries.max.width['1280'],
  ])]: {
    padding: '0 1.5rem',
  },
}));

export default SectionWrapper;
