import Devices from 'styles/icons/Devices';
import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const IconWrapper = styled(Devices)(({ theme }) => ({
  height: '100%',
  paddingLeft: '4.5rem',
  width: '100%',

  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    padding: 0,
  },

  [mediaQueryBuilder([
    theme.mediaQueries.max.width['768'],
    theme.mediaQueries.min.width['599'],
  ])]: {
    height: '100vh',
    marginTop: 0,
    position: 'absolute',
    transform: 'translateX(17rem)',
    width: '100vw',
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['599'])]: {
    marginTop: '-4rem',
    transform: 'translateY(9rem)',
  },
}));

export default IconWrapper;
