import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const DevicesLeft = styled('div')(({ theme }) => ({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '50rem',
  width: '68%',

  [mediaQueryBuilder(theme.mediaQueries.max.width['599'])]: {
    maxWidth: 'none',
    width: '100%',
  },
}));

export default DevicesLeft;
