import NewsSection from './NewsSection';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getContentLink, getNewsLink } from 'state/Links/selectors';
import { getHomepageNewsSection } from 'state/Features/selectors';
import { getNewsDirectory } from 'state/News/selectors';
import { NewsArticle } from './types';
import { State } from 'state/types';

export default connect(
  createStructuredSelector<
    State,
    {
      contentLink: string;
      homepageNewsSection: boolean;
      newsArticles: Array<NewsArticle>;
      newsLink: string;
    }
  >({
    contentLink: getContentLink,
    homepageNewsSection: getHomepageNewsSection,
    newsArticles: getNewsDirectory,
    newsLink: getNewsLink,
  }),
)(NewsSection);
