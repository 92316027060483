import styled from '@emotion/styled';

const CardInfo = styled('div')(({ theme }) => ({
  background: theme.colors.white.primary,
  bottom: 0,
  height: '9rem',
  padding: '1rem',
  position: 'absolute',
  width: '100%',
}));

export default CardInfo;
