import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const ButtonsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  marginBottom: '1.5rem',

  [mediaQueryBuilder([
    theme.mediaQueries.max.width['599'],
    theme.mediaQueries.min.width['375'],
  ])]: {
    flexDirection: 'row',
  },
}));

export default ButtonsContainer;
