import BR from 'primitives/Layout/BR';
import CatalogImage from 'components/MediaServerImage/CatalogImage';
import H1 from 'primitives/Typography/Headings/H1';
import Link from '../primitives/Link';
import LinkIcon from 'views/Welcome/primitives/LinkIcon';
import LiveSelectionSelectBox from '../primitives/LiveSelectionSelectBox';
import NavLink from 'components/NavLink';
import P from 'primitives/Typography/BodyCopy/P';
import PlayButtonContainer from 'components/Player/PlayButtonContainer';
import PlayButtonContainerPrimitive from 'components/Artist/PlayButtonContainer';
import PlayerStateProxy from 'components/Player/PlayerState/PlayerStateProxy';
import SelectBox from 'web-ui/templates/FormElements/SelectBox';
import Tile from 'components/Tile/Tile';
import Tiles from 'components/Tiles/Tiles';
import TilesImageWrapper from 'components/Tile/primitives/TilesImageWrapper';
import transport from 'api/transport';
import WelcomeSection from './primitives/WelcomeSection';
import { getCountryOptions } from 'state/Location/services';
import { getLiveMarketUrl, getMarketName } from 'state/Live/helpers';
import { LiveSectionProps } from './types';
import { Market } from 'state/Location/types';
import { PureComponent } from 'react';

const PlayButton = PlayerStateProxy(PlayButtonContainer);

async function getCountryOptionsPromise(ampUrl: string) {
  const { data: countryOptions } = await transport(getCountryOptions(ampUrl));
  return countryOptions;
}

class LiveSection extends PureComponent<LiveSectionProps> {
  componentDidMount() {
    this.redirectToGeoMarket();
  }

  componentDidUpdate() {
    this.redirectToGeoMarket();
  }

  updateMarket = (market: Market) => {
    const { currentCountry, currentGenre, navigate } = this.props;
    const id = currentGenre?.id;
    const path = getLiveMarketUrl(market, currentCountry, id);
    navigate({ path });
  };

  redirectToGeoMarket() {
    const {
      currentCountry,
      currentMarket,
      currentGenre,
      geoMarket,
      setCountry,
      setMarket,
      setCountryOptions,
      ampUrl,
    } = this.props;
    if (
      (!currentCountry || !Object.keys(currentCountry).length) &&
      (!currentMarket || !Object.keys(currentMarket).length) &&
      (!currentGenre || !Object.keys(currentGenre).length) &&
      geoMarket
    ) {
      const { countryAbbreviation, countryName, countryId } = geoMarket;

      getCountryOptionsPromise(ampUrl).then(countryOptions => {
        setCountryOptions(countryOptions?.hits ?? []);
      });

      setCountry({
        abbreviation: countryAbbreviation,
        id: countryId,
        name: countryName,
      }).then(() => setMarket(geoMarket));
    }
  }

  render() {
    const {
      currentMarket,
      horizontalScroll,
      liveLink,
      marketOptions,
      singleRow,
      stations = [],
      tilesInRow,
      translate,
    } = this.props;
    const stationTiles = stations?.slice(0, 4) ?? [];

    let tiles = [];
    for (let i = 0; i < tilesInRow; i += 1) {
      tiles.push(
        <Tile
          horizontalScroll={horizontalScroll}
          isDummyTile
          key={i}
          singleRow={singleRow}
          tilePosition={i}
          tilesInRow={tilesInRow}
        />,
      );
    }

    if (stations) {
      tiles = stationTiles.map(
        (
          {
            callLetters,
            description,
            imgWidth,
            logo,
            name,
            playedFrom,
            rawLogo,
            seedId,
            seedType,
            url,
          },
          i,
        ) => (
          <Tile
            horizontalScroll={horizontalScroll}
            key={`live-directory|${seedId}`}
            singleRow={singleRow}
            subTitle={description}
            tileDelay={0}
            tilePosition={i}
            tilesInRow={tilesInRow}
            title={name}
            url={url}
          >
            <NavLink
              css={{ display: 'block', position: 'relative' }}
              itemSelected={{
                id: seedId,
                location: 'home|live_radio|tile',
                name: callLetters,
                type: seedType,
              }}
              to={url}
            >
              <PlayButtonContainerPrimitive data-test="live-directory-tile-play-button-container">
                <PlayButton
                  className="play"
                  deferPlay={!!url}
                  playedFrom={playedFrom}
                  seedId={seedId}
                  stationId={seedId}
                  stationType={seedType}
                />
              </PlayButtonContainerPrimitive>
              <TilesImageWrapper liveTile>
                <CatalogImage
                  alt={name}
                  aspectRatio={1}
                  height={imgWidth}
                  id={seedId}
                  src={logo || rawLogo}
                  type={seedType}
                  width={imgWidth}
                />
              </TilesImageWrapper>
            </NavLink>
          </Tile>
        ),
      );
    }

    return (
      <WelcomeSection bottomDivider dataTest="welcome-page-live-section">
        {/* H1 for styles, h2 for sequential headers in DOM */}
        <H1 as="h2" data-test="welcome-page-live-header">
          {translate('Live Radio')}
        </H1>
        <P data-test="welcome-page-live-text">
          {translate(
            'The best live stations from around the country, any time, any place.',
          )}
        </P>
        <LiveSelectionSelectBox data-test="live-select-city">
          <SelectBox
            dataTest="cityDropdown"
            name="city"
            onChange={this.updateMarket}
            options={[
              {
                title: translate('All Cities'),
                value: null,
              },
              ...marketOptions.map(market => ({
                title: getMarketName(market),
                value: market,
              })),
            ]}
            selectClasses="short"
            selectedOption={{
              title: getMarketName(currentMarket),
              value: currentMarket,
            }}
          />
        </LiveSelectionSelectBox>
        <BR />
        <Tiles horizontalScroll={horizontalScroll} tilesInRow={tilesInRow}>
          {tiles}
        </Tiles>

        <Link floatRight to={liveLink} underline={false}>
          {translate('View More Live Stations')}
          <LinkIcon name="caret-right" />
        </Link>
      </WelcomeSection>
    );
  }
}

export default LiveSection;
