import styled from '@emotion/styled';

type Props = {
  isVideoOpen: boolean;
};

const Video = styled('video')<Props>(({ isVideoOpen = false, theme }) => ({
  animation: `${theme.keyframes.fadeIn} 300ms ease-in-out`,
  animationDelay: isVideoOpen ? '0s' : '1.9s',
  animationFillMode: 'forwards',
  borderRadius: '0.3rem',
  height: isVideoOpen ? 'auto' : '100%',
  objectFit: isVideoOpen ? 'contain' : 'none',
  opacity: 0,
  transition: 'all 300ms ease-in-out',
  width: '100%',
}));

export default Video;
