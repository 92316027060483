import styled from '@emotion/styled';

const DevicesRight = styled('div')({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  position: 'relative',
  width: '100%',
});

export default DevicesRight;
