import { Children, ReactNode } from 'react';

type Props = {
  children: ReactNode;
  dataAnalyticsRegion: string;
  limit?: number;
};

function Cards({ children, dataAnalyticsRegion, limit = Infinity }: Props) {
  return (
    <div data-analytics-region={dataAnalyticsRegion}>
      {Children.toArray(children).slice(0, limit)}
    </div>
  );
}

export default Cards;
