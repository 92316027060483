import Body2 from 'primitives/Typography/BodyCopy/Body2';
import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const DevicesWrapper = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',

  [Body2.toString()]: {
    marginBottom: '3.6rem',
    marginTop: '2rem',
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['599'])]: {
    flexDirection: 'column',
  },
}));

export default DevicesWrapper;
