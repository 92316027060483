import ButtonsWrapper from './primitives/ButtonsWrapper';
import CloseIcon from 'styles/icons/CloseIcon';
import FilledButton from 'primitives/Buttons/FilledButton';
import H1 from 'primitives/Typography/Headings/H1';
import HeroLeft from './primitives/HeroLeft';
import HeroRight from './primitives/HeroRight';
import Links from './Links';
import NavLink from 'primitives/Typography/Links/NavLink';
import OutlinedButton from 'primitives/Buttons/OutlinedButton';
import Play from 'styles/icons/Play';
import ShouldShow from 'components/ShouldShow';
import theme from 'styles/themes/default';
import useMount from 'hooks/useMount';
import Video from './primitives/Video';
import VideoClose from './primitives/VideoClose';
import VideoCover from './primitives/VideoCover';
import VideoPlay from './primitives/VideoPlay';
import WelcomeSection from './primitives/WelcomeSection';
import Wrapper from './primitives/Wrapper';
import { IGetTranslateFunctionResponse } from 'redux-i18n';
import { useRef, useState } from 'react';
import type { User } from 'state/User/types';

type Props = {
  appLink: string;
  liveLink: string;
  newsLink: string;
  playlistLink: string;
  podcastLink: string;
  translate: IGetTranslateFunctionResponse;
  user: User;
  fetchGenrePreferences: () => Promise<void>;
  selectedGenres: { [id: string]: boolean | undefined } | undefined;
};

type State = {
  shouldShowVideo: boolean;
  isVideoOpen: boolean;
};

function HeroSection({
  appLink,
  fetchGenrePreferences,
  translate,
  liveLink,
  newsLink,
  playlistLink,
  podcastLink,
  selectedGenres,
  user,
}: Props) {
  const video = useRef<HTMLVideoElement | null>(null);

  const [state, _setState] = useState({
    isVideoOpen: false,
    shouldShowVideo: false,
  });
  // use ref to access state in event listeners
  const stateRef = useRef<State>(state);
  function setState(newState: State): void {
    stateRef.current = newState;
    _setState(newState);
  }

  // check window size for small screens
  function getIsSmallScreen(): boolean {
    // per spec in IHRWEB-12259, video container <HeroRight/> is hidden with mediaQuery max width = 599
    const SHOW_VIDEO_WIDTH = 599;
    return window.innerWidth <= SHOW_VIDEO_WIDTH;
  }

  // callback for resize events. Set shouldShowVideo to isSmallScreen
  function updateShouldShowVideo(): void {
    const shouldShowVideo = !getIsSmallScreen();
    // only update if hit break point
    if (shouldShowVideo !== stateRef.current.shouldShowVideo)
      setState({ ...stateRef.current, shouldShowVideo });
  }

  // replace componentDidMount
  useMount((): (() => void) => {
    setState({ ...state, shouldShowVideo: !getIsSmallScreen() });

    if (!selectedGenres) fetchGenrePreferences();

    // detect resize and set should show
    window.addEventListener('resize', updateShouldShowVideo);

    // replace componentWillUnmount
    return (): void => {
      // remove resize event listener when user navigates away
      window.removeEventListener('resize', updateShouldShowVideo);
    };
  });

  function openCloseVideo(): void {
    const { isVideoOpen: currVideoState } = state;
    setState({ ...state, isVideoOpen: !currVideoState });
    if (video?.current) video.current.load();
  }

  const { abTestGroups } = user;
  const { isVideoOpen, shouldShowVideo } = state;

  const videoURL =
    isVideoOpen ?
      'https://web-static.pages.iheart.com/welcome/web_home_video_full.mp4'
    : 'https://web-static.pages.iheart.com/welcome/web_home_video_loop.mp4';

  const aBTestButtonString =
    abTestGroups.webValidationTest === 'B' ?
      translate('Get Started Free')
    : translate('Get Started');

  return (
    <WelcomeSection
      background={`linear-gradient(180deg, ${theme.colors.gray['100']} 0%, ${theme.colors.gray['200']} 100%)`}
      dataTest="hero"
    >
      <Wrapper>
        <HeroLeft>
          <H1>
            {translate(
              'All your favorite music, radio and podcasts, all free.',
            )}
          </H1>
          <ButtonsWrapper
            aBTestButtonString={abTestGroups.webValidationTest === 'B'}
          >
            <NavLink
              to={
                selectedGenres && Object.keys(selectedGenres).length ?
                  '/for-you/'
                : '/genre-options/'
              }
              underline={false}
            >
              <FilledButton data-test="hero-button-get-started">
                {aBTestButtonString}
              </FilledButton>
            </NavLink>

            <NavLink
              data-test="hero-button-get-app"
              to={appLink}
              underline={false}
            >
              <OutlinedButton data-test="hero-button-get-app">
                {translate('Get the App')}
              </OutlinedButton>
            </NavLink>
          </ButtonsWrapper>
        </HeroLeft>

        <HeroRight isVideoOpen={isVideoOpen}>
          <ShouldShow shouldShow={shouldShowVideo}>
            <VideoCover />

            <Video
              autoPlay
              controls={isVideoOpen}
              data-test="hero-video"
              isVideoOpen={isVideoOpen}
              loop
              muted={!isVideoOpen}
              playsInline
              poster="https://web-static.pages.iheart.com/welcome/home-video-poster.jpg"
              ref={video}
            >
              <source src={videoURL} type="video/mp4" />
              {translate('Your browser does not support video playback')}
            </Video>

            <VideoPlay isVideoOpen={isVideoOpen} onClick={openCloseVideo}>
              <Play data-test="hero-video-play-icon" />
            </VideoPlay>

            <VideoClose isVideoOpen={isVideoOpen} onClick={openCloseVideo}>
              <CloseIcon />
            </VideoClose>
          </ShouldShow>
        </HeroRight>
      </Wrapper>
      <Links
        live={liveLink}
        news={newsLink}
        playlists={playlistLink}
        podcasts={podcastLink}
      />
    </WelcomeSection>
  );
}

export default HeroSection;
