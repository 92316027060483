import H1 from 'primitives/Typography/Headings/H1';
import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const HeroLeft = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  maxWidth: '33rem',

  [mediaQueryBuilder([
    theme.mediaQueries.max.width['1160'],
    theme.mediaQueries.min.width['768'],
  ])]: {
    [H1.toString()]: {
      fontSize: theme.fonts.size['32'],
      lineHeight: theme.fonts.lineHeight['38'],
    },
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    maxWidth: 'none',
  },

  [mediaQueryBuilder([
    theme.mediaQueries.max.width['768'],
    theme.mediaQueries.min.width['599'],
  ])]: {
    paddingLeft: '3rem',
    paddingRight: '3rem',
  },
}));

export default HeroLeft;
