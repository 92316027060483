import NavLink from 'components/NavLink';
import styled from '@emotion/styled';

const CardContainer = styled(NavLink)(({ theme }) => ({
  '&:hover': {
    boxShadow: '0px 5px 8px rgba(0, 0, 0, 0.12)',
    transform: 'translate(0,-3px)',
    transition: '0.2s ease-in-out',
  },
  background: theme.colors.white.primary,
  borderRadius: '5px',
  boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.15)',
  display: 'block',
  height: '9rem',
  marginBottom: '1rem',
  overflow: 'auto',
  padding: '1rem',
}));

export default CardContainer;
