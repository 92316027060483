import Caption from 'primitives/Typography/BodyCopy/Caption';
import styled from '@emotion/styled';

const CardCaption = styled(Caption)(({ theme }) => ({
  bottom: '1rem',
  color: theme.colors.gray['400'],
  position: 'absolute',
}));

export default CardCaption;
