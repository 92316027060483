import theme from 'styles/themes/default';

type Props = {
  'data-test'?: string;
  className?: string;
  color?: string;
};

function News({ color = theme.colors.gray['500'], ...props }: Props) {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" {...props}>
      <path
        clipRule="evenodd"
        d="M6 3C5.17163 3 4.5 3.67157 4.5 4.5V19.5C4.5 20.3284 5.17163 21 6 21H18C18.8284 21 19.5 20.3284 19.5 19.5V4.5C19.5 3.67157 18.8284 3 18 3H6ZM7.31055 11.1817H11.6904C11.8616 11.1817 12.0005 11.0229 12.0005 10.8267V5.80956C12.0005 5.61334 11.8616 5.45448 11.6904 5.45448H7.31055C7.1394 5.45448 7.00049 5.61334 7.00049 5.80956V10.8267C7.00049 11.0229 7.1394 11.1817 7.31055 11.1817ZM14.0759 5.45448C13.8499 5.45448 13.6667 5.63764 13.6667 5.86357C13.6667 6.08951 13.8499 6.27266 14.0759 6.27266H16.5911C16.8169 6.27266 17 6.08951 17 5.86357C17 5.63764 16.8169 5.45448 16.5911 5.45448H14.0759ZM13.6667 8.31822C13.6667 8.09229 13.8499 7.90913 14.0759 7.90913H16.5911C16.8169 7.90913 17 8.09229 17 8.31822C17 8.54416 16.8169 8.72731 16.5911 8.72731H14.0759C13.8499 8.72731 13.6667 8.54416 13.6667 8.31822ZM14.0759 10.3636C13.8499 10.3636 13.6667 10.5468 13.6667 10.7727C13.6667 10.9987 13.8499 11.1818 14.0759 11.1818H16.5911C16.8169 11.1818 17 10.9987 17 10.7727C17 10.5468 16.8169 10.3636 16.5911 10.3636H14.0759ZM7 13.2272C7 13.0013 7.18311 12.8181 7.40918 12.8181H16.5908C16.8169 12.8181 17 13.0013 17 13.2272C17 13.4532 16.8169 13.6363 16.5908 13.6363H7.40918C7.18311 13.6363 7 13.4532 7 13.2272ZM7.40918 15.2728C7.18311 15.2728 7 15.4559 7 15.6819C7 15.9078 7.18311 16.091 7.40918 16.091H16.5908C16.8169 16.091 17 15.9078 17 15.6819C17 15.4559 16.8169 15.2728 16.5908 15.2728H7.40918ZM7 18.1363C7 17.9104 7.18311 17.7272 7.40918 17.7272H16.5908C16.8169 17.7272 17 17.9104 17 18.1363C17 18.3623 16.8169 18.5454 16.5908 18.5454H7.40918C7.18311 18.5454 7 18.3623 7 18.1363Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export default News;
