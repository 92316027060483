import ArtistSection from './ArtistSection';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getCurrentArtistDirectoryRecs } from 'state/Recs/selectors';
import { getCustomRadioLink } from 'state/Links/selectors';
import { Rec } from 'state/Recs/types';
import { State } from 'state/types';

export default connect(
  createStructuredSelector<
    State,
    {
      artistsLink: string;
      recs: Array<Rec & { name: string; seedId: number; seedType: string }>;
    }
  >({
    artistsLink: getCustomRadioLink,
    recs: getCurrentArtistDirectoryRecs,
  }),
)(ArtistSection);
