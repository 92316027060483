import BR from 'primitives/Layout/BR';
import Card from 'components/Card/Card';
import Cards from 'components/Cards/Cards';
import CardsContainer from './primitives/CardsContainer';
import CardsContainerSmall from './primitives/CardsContainerSmall';
import CardSmall from 'components/Card/CardSmall';
import H2 from 'primitives/Typography/Headings/H2';
import Link from '../primitives/Link';
import LinkIcon from 'views/Welcome/primitives/LinkIcon';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import WelcomeSection from 'components/WelcomeSection';
import { NewsArticle } from './types';

type Props = {
  contentLink: string;
  homepageNewsSection: boolean;
  newsArticles: Array<NewsArticle>;
  newsLink: string;
};

function NewsSection({
  contentLink,
  homepageNewsSection,
  newsLink,
  newsArticles,
}: Props) {
  const translate = useTranslate();

  if (!homepageNewsSection || !newsArticles.length) return null;

  return (
    <WelcomeSection bottomDivider dataTest="news">
      <CardsContainer>
        <H2>{translate('Latest Music News')}</H2>
        <BR />
        <Cards dataAnalyticsRegion="news_tile" limit={4}>
          {newsArticles.map(article => {
            const {
              image,
              pub_changed: pubChanged,
              pub_start: pubStart,
              show_updated_timestamp: showUpdatedTimestamp,
              slug,
              title,
            } = article;
            return (
              <Card
                image={image}
                key={article.slug}
                timestamp={showUpdatedTimestamp ? pubChanged : pubStart}
                title={title}
                url={`${contentLink}${slug}`}
              />
            );
          })}
        </Cards>
      </CardsContainer>
      <CardsContainerSmall>
        <Cards dataAnalyticsRegion="top_stories_rail" limit={6}>
          {newsArticles.slice(4, 10).map(article => {
            const { image, slug, title } = article;
            return (
              <CardSmall
                image={image}
                key={slug}
                title={title}
                url={`${contentLink}${slug}`}
              />
            );
          })}
        </Cards>
      </CardsContainerSmall>
      <Link floatRight target="_blank" to={newsLink} underline={false}>
        {translate('View More Music News')}
        <LinkIcon data-test="welcome-news-link" name="caret-right" />
      </Link>
    </WelcomeSection>
  );
}

export default NewsSection;
