import loadable from '@loadable/component';
import {
  assetUrl,
  backgroundColor,
  btnTitle,
  imgUrl,
  name,
  primaryBackgroundSrc,
  url,
} from 'state/Hero/selectors';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { State } from 'state/types';
import { trackItemSelected } from 'state/Hero/actions';

const NewInAppSection = loadable(() => import('./NewInAppSection'));

export default connect(
  createStructuredSelector<
    State,
    {
      assetUrl: string;
      backgroundColor: string;
      btnTitle: string;
      imgUrl: string;
      name: string;
      primaryBackgroundSrc: string;
      target: string | null;
      url: string;
    }
  >({
    assetUrl,
    backgroundColor,
    btnTitle,
    imgUrl,
    name,
    primaryBackgroundSrc,
    target: state => state.hero.target,
    url,
  }),
  { trackItemSelected },
)(NewInAppSection);
