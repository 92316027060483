import GenericHead from 'components/GenericHead';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import {
  getCustomRadioEnabled,
  getOnDemandEnabled,
} from 'state/Features/selectors';
import { getShareMessage } from 'constants/sharing';
import { Helmet } from 'react-helmet';
import { translateKeywords } from 'utils/metaUtils';
import { useSelector } from 'react-redux';

export default function Head() {
  const customRadioEnabled = useSelector(getCustomRadioEnabled);
  const onDemandEnabled = useSelector(getOnDemandEnabled);
  const translate = useTranslate();

  const title = translate(
    'Listen to Your Favorite Music, Podcasts, and Radio Stations for Free! – iHeart',
  );
  const keywords = translateKeywords(
    translate,
    'iHeartRadio, iHeart, Radio, Internet, Music, Talk, Listen, Live, Artist, Song, Playlist, On Demand, Discover, Personalized, Free, App, Online',
  );

  return (
    <>
      <GenericHead
        description={getShareMessage(
          translate,
          customRadioEnabled,
          onDemandEnabled,
        )}
        metaDescription={translate(
          'iHeart. All your favorite music, podcasts, and radio stations available for free. Listen to thousands of live radio stations or create your own artist stations and playlists. Get the latest music and trending news, from your favorite artists and bands.',
        )}
        ogType="website"
        omitTitleEnding
        title={title}
      />
      <Helmet
        meta={[
          { content: title, property: 'name' },
          { content: keywords, name: 'keywords' },
        ]}
        titleTemplate="%s"
      />
    </>
  );
}
