import Body1 from 'primitives/Typography/BodyCopy/Body1';
import Caption from 'primitives/Typography/BodyCopy/Caption';
import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import NavLink from 'components/NavLink';
import styled from '@emotion/styled';

const DevicesButton = styled(NavLink)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.colors.gray['600'],
  border: 'none',
  borderRadius: '0.6rem',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '1.5rem',
  outline: 'none',
  padding: '1rem 1.8rem',

  [`${Body1.toString()}, ${Caption.toString()}`]: {
    color: theme.colors.white.primary,
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    marginRight: '1.5rem',
  },
}));

export default DevicesButton;
