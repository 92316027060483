import FilledButton from 'primitives/Buttons/FilledButton';
import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

type Props = {
  aBTestButtonString: boolean;
};

const ButtonsWrapper = styled('div')<Props>(
  ({ aBTestButtonString = false, theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginBottom: '4rem',
    marginTop: '0.75rem',
    maxWidth: aBTestButtonString ? '32.5rem' : '28.5rem',
    width: '100%',

    a: {
      marginBottom: '0.75rem',
    },

    [FilledButton.toString()]: {
      marginLeft: 0,
    },

    [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
      marginBottom: 0,
    },
  }),
);

export default ButtonsWrapper;
