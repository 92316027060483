import Icon from 'components/Icon/Icon';
import styled from '@emotion/styled';

const LinkIcon = styled(Icon)({
  display: 'block',
  float: 'right',
  margin: '0.2rem 0 0 1rem',
});

export default LinkIcon;
