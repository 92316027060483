import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',

  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    flexDirection: 'column',
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['599'])]: {
    paddingLeft: '2.5rem',
    paddingRight: '2.5rem',
  },
}));

export default Wrapper;
