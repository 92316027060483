import styled from '@emotion/styled';

type Props = {
  isVideoOpen: boolean;
};

const VideoClose = styled('div')<Props>(({ isVideoOpen = false, theme }) => ({
  cursor: 'pointer',
  opacity: isVideoOpen ? 1 : 0,
  position: 'absolute',
  right: 0,
  top: 0,
  transform: isVideoOpen ? 'translate(-1rem, 1rem)' : 'translate(-1rem, 2rem)',
  transition: 'all 300ms ease-in-out',

  svg: {
    fill: theme.colors.white.primary,
    height: '3rem',
  },
}));

export default VideoClose;
