import Links, { ConnectedProps } from './Links';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  getInternationalPlaylistRadioEnabled,
  getOnDemandEnabled,
} from 'state/Features/selectors';
import type { State } from 'state/types';

export default connect(
  createStructuredSelector<State, ConnectedProps>({
    internationalPlaylistRadioEnabled: getInternationalPlaylistRadioEnabled,
    onDemandEnabled: getOnDemandEnabled,
  }),
)(Links);
