import PodcastSection from './PodcastSection';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { flowRight } from 'lodash-es';
import { getFeaturedCategoryPodcasts } from 'state/Podcast/selectors';
import { getPodcasts } from 'state/Podcast/actions';
import { getPodcastsLink } from 'state/Links/selectors';
import { localize } from 'redux-i18n';
import { Podcast } from 'state/Podcast/types';
import { State } from 'state/types';

export default flowRight(
  localize('translate'),
  connect(
    createStructuredSelector<
      State,
      {
        podcasts: Array<Podcast>;
        podcastsLink: string;
      }
    >({
      podcasts: getFeaturedCategoryPodcasts,
      podcastsLink: getPodcastsLink,
    }),
    { getPodcasts },
  ),
)(PodcastSection);
