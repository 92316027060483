import BR from 'primitives/Layout/BR';
import CardCaption from './primitives/CardCaption';
import CardContainer from './primitives/CardContainer';
import CardImageContainer from './primitives/CardImageContainer';
import CardInfo from './primitives/CardInfo';
import format from 'date-fns/format';
import MediaServerImage from 'components/MediaServerImage';
import Truncate from 'components/Truncate';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { gravity, max } from 'utils/mediaServerImageMaker/opsString';

type Props = {
  image: string;
  timestamp: number;
  title: string;
  url: string;
};

function Card({ image, title, timestamp, url }: Props) {
  const translate = useTranslate();

  const publishDate =
    (
      timestamp && Date.now() - timestamp > 60 * 60 * 1000 // we consider "just now" to be within the last hour
    ) ?
      format(new Date(timestamp), 'MMMM D, YYYY')
    : translate('Trending - Just Now');

  return (
    <CardContainer target="_blank" to={url}>
      <CardImageContainer>
        <MediaServerImage
          alt={title}
          aspectRatio={1.95}
          background
          ops={[gravity('north'), max(400, 300)]}
          src={image}
        />
      </CardImageContainer>
      <CardInfo>
        <Truncate lines={2}>{title}</Truncate>
        <BR />
        <CardCaption>{publishDate}</CardCaption>
      </CardInfo>
    </CardContainer>
  );
}

export default Card;
