import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const LiveSelectBox = styled('div')(({ theme }) => ({
  float: 'right',
  marginTop: '-3.5rem',
  paddingLeft: '2.5rem',
  width: '25%',
  [mediaQueryBuilder(theme.mediaQueries.max.width['1160'])]: {
    float: 'none',
    marginTop: '1rem',
    padding: '0 1rem 0 0',
    width: '33.33%',
  },
  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    width: '50%',
  },
}));

export default LiveSelectBox;
