import LinkPrimitive from 'primitives/Typography/Links/Link';
import styled from '@emotion/styled';

const Link = styled(LinkPrimitive)(({ theme }) => ({
  textDecoration: 'none',

  '&:hover': {
    color: theme.colors.red['600'],
    textDecoration: 'underline',
  },
}));

export default Link;
