import styled from '@emotion/styled';

type Props = {
  textAlign?: 'left' | 'center';
};

const H2 = styled('h2')<Props>(({ textAlign = 'left', theme }) => ({
  color: theme.colors.gray['600'],
  fontSize: theme.fonts.size['32'],
  fontWeight: theme.fonts.weight.bold,
  letterSpacing: '-0.05rem',
  lineHeight: theme.fonts.lineHeight['38'],
  margin: '0.8rem 0',
  textAlign,
  transition: 'all 300ms ease-in-out',
}));

export default H2;
