import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';
import WelcomeSectionComponent from 'components/WelcomeSection';

const WelcomeSection = styled(WelcomeSectionComponent)(({ theme }) => ({
  paddingTop: '9rem',

  [mediaQueryBuilder([
    theme.mediaQueries.max.width['980'],
    theme.mediaQueries.min.width['599'],
  ])]: {
    paddingTop: '9rem',
  },
}));

export default WelcomeSection;
