import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const CardsContainer = styled('div')(({ theme }) => ({
  float: 'left',
  marginBottom: '2rem',
  width: '70%',
  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    width: '100%',
  },
}));

export default CardsContainer;
