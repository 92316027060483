import CardSmallContainer from './primitives/CardSmallContainer';
import CardSmallImageContainer from './primitives/CardSmallImageContainer';
import CardSmallInfo from './primitives/CardSmallInfo';
import MediaServerImage from 'components/MediaServerImage';
import Truncate from 'components/Truncate';
import { gravity, max } from 'utils/mediaServerImageMaker/opsString';

type Props = {
  image: string;
  title: string;
  url: string;
};

function CardSmall({ image, title, url }: Props) {
  return (
    <CardSmallContainer target="_blank" to={url}>
      <CardSmallImageContainer>
        <MediaServerImage
          alt={title}
          aspectRatio={1.28}
          background
          ops={[gravity('north'), max(100, 100)]}
          src={image}
        />
      </CardSmallImageContainer>
      <CardSmallInfo>
        <Truncate lines={3}>{title}</Truncate>
      </CardSmallInfo>
    </CardSmallContainer>
  );
}

export default CardSmall;
