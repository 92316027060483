import ContentMain from 'primitives/Layout/ContentMain';
import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const WelcomeWrapper = styled(ContentMain)(({ theme }) => ({
  overflowX: 'hidden',
  paddingTop: 0,

  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    marginTop: 0,
  },
}));

export default WelcomeWrapper;
