import BR from 'primitives/Layout/BR';
import CatalogImage from 'components/MediaServerImage/CatalogImage';
import H1 from 'primitives/Typography/Headings/H1';
import Link from '../primitives/Link';
import LinkIcon from 'views/Welcome/primitives/LinkIcon';
import NavLink from 'components/NavLink';
import P from 'primitives/Typography/BodyCopy/P';
import PlayButtonContainer from 'components/Player/PlayButtonContainer';
import PlayButtonContainerPrimitive from 'components/Artist/PlayButtonContainer';
import PLAYED_FROM from 'modules/Analytics/constants/playedFrom';
import PlayerStateProxy from 'components/Player/PlayerState/PlayerStateProxy';
import Tile from 'components/Tile/Tile';
import Tiles from 'components/Tiles/Tiles';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import WelcomeSection from 'components/WelcomeSection';
import { Rec } from 'state/Recs/types';
import { StationTypeValue } from 'constants/stationTypes';

type Props = {
  artistsLink: string;
  horizontalScroll?: boolean;
  recs: Array<Rec & { name: string; seedId: number; seedType: string }>;
  singleRow?: boolean;
  tilesInRow: number;
};

const PlayButton = PlayerStateProxy(PlayButtonContainer);

function ArtistSection({
  artistsLink = '/artist/',
  horizontalScroll,
  recs = [],
  singleRow,
  tilesInRow,
}: Props) {
  const translate = useTranslate();
  const limit = singleRow ? tilesInRow : Infinity;
  const recsTiles = recs?.slice(0, limit) ?? [];

  return (
    <WelcomeSection bottomDivider dataTest="artist">
      {/* H1 for styles, h2 for sequential headers in DOM */}
      <H1 as="h2" data-test="welcome-page-artist-header">
        {translate('Artist Radio')}
      </H1>
      <P data-test="welcome-page-artist-text">
        {translate(
          'Custom radio stations based off your favorite artists and bands.',
        )}
      </P>
      <BR />
      <Tiles horizontalScroll={horizontalScroll} tilesInRow={tilesInRow}>
        {recsTiles.map((artist, i) => {
          const { name, url, seedType, seedId } = artist;
          const imgWidth = 240;
          return (
            <Tile
              horizontalScroll={horizontalScroll}
              isRoundImage
              key={seedId}
              singleRow
              tilePosition={i}
              tilesInRow={tilesInRow}
              title={name}
              url={url}
            >
              <NavLink
                css={{ display: 'block', position: 'relative' }}
                itemSelected={{
                  id: seedId,
                  location: 'home|artist_radio|tile',
                  name,
                  type: seedType,
                }}
                to={url}
              >
                <PlayButtonContainerPrimitive data-test="play-button-container">
                  <PlayButton
                    className="play"
                    deferPlay={!!url}
                    playedFrom={PLAYED_FROM.DIR_CUSTOM_MAIN_FEATURED_ARTISTS}
                    seedId={seedId}
                    stationId={seedId}
                    stationType={seedType}
                  />
                </PlayButtonContainerPrimitive>
                <CatalogImage
                  alt={name}
                  aspectRatio={1}
                  height={imgWidth}
                  id={seedId}
                  type={seedType as StationTypeValue}
                  width={imgWidth}
                />
              </NavLink>
            </Tile>
          );
        })}
      </Tiles>

      <Link floatRight to={artistsLink} underline={false}>
        {translate('View More Artists')}
        <LinkIcon name="caret-right" />
      </Link>
    </WelcomeSection>
  );
}

export default ArtistSection;
