import DevicesSection from './DevicesSection';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getAppsMobileLink } from 'state/Links/selectors';
import { State } from 'state/types';

export default connect(
  createStructuredSelector<State, { ihrAppsLink: string }>({
    ihrAppsLink: getAppsMobileLink,
  }),
)(DevicesSection);
