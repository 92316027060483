import styled from '@emotion/styled';

const CardSmallImageContainer = styled('div')({
  borderRadius: '0.3rem',
  float: 'left',
  height: '7rem',
  marginRight: '1rem',
  overflow: 'hidden',
  width: '9rem',
});

export default CardSmallImageContainer;
