import styled from '@emotion/styled';

const VideoCover = styled('div')(({ theme }) => ({
  animation: `${theme.keyframes.fillUp} 500ms ease-in-out 1.1s, ${theme.keyframes.fadeOut} 0s linear 1.9s, ${theme.keyframes.minimizeSize} 0s linear 1.9s`,
  animationFillMode: 'forwards',
  backgroundColor: theme.colors.gray['300'],
  backgroundSize: '200% 200%',
  borderRadius: '0.3rem',
  height: '100%',
  position: 'absolute',
  transform: 'translateY(100%)',
  width: '100%',
}));

export default VideoCover;
