import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import SectionWrapper from 'components/WelcomeSection/primitives/SectionWrapper';
import styled from '@emotion/styled';
import WelcomeSectionComponent from 'components/WelcomeSection';

const WelcomeSection = styled(WelcomeSectionComponent)(({ theme }) => ({
  overflow: 'visible',
  padding: '6rem',
  position: 'relative',
  zIndex: theme.zIndex.welcomeSection,

  [mediaQueryBuilder(theme.mediaQueries.max.width['1280'])]: {
    paddingBottom: '2rem',
    paddingTop: '6rem',
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    paddingBottom: 0,
    paddingTop: '4rem',
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['599'])]: {
    paddingLeft: 0,
    paddingRight: 0,

    [SectionWrapper.toString()]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
}));

export default WelcomeSection;
