import theme from 'styles/themes/default';

type Props = {
  'data-test'?: string;
  className?: string;
};

function GooglePlay(props: Props) {
  return (
    <svg {...props} fill="none" height="29" viewBox="0 0 26 29" width="26">
      <path
        d="M0 26.9127V1.97911C0 1.10903 0.504429 0.356931 1.23644 0L15.6593 14.4416L1.23064 28.8889C0.501763 28.5309 0 27.7805 0 26.9127ZM20.235 19.0231L4.46453 28.14L16.9023 15.6861L20.235 19.0231ZM25.1371 12.6979C25.6616 13.0999 26 13.7334 26 14.4459C26 15.1477 25.6719 15.7727 25.1608 16.1754L21.811 18.112L18.1452 14.4416L21.8085 10.7736L25.1371 12.6979ZM4.47377 0.752481L20.2325 9.86255L16.9023 13.197L4.47377 0.752481Z"
        fill={theme.colors.white.primary}
      />
    </svg>
  );
}

export default GooglePlay;
