import styled from '@emotion/styled';

type Props = {
  isVideoOpen: boolean;
};

const VideoPlay = styled('div')<Props>(({ isVideoOpen = false, theme }) => ({
  alignItems: 'center',
  animation: `${theme.keyframes.fadeOut} 0s linear 1.9s`,
  backgroundColor: theme.colors.white.primary,
  borderRadius: '50%',
  cursor: 'pointer',
  display: isVideoOpen ? 'none' : 'flex',
  height: '7rem',
  justifyContent: 'center',
  position: 'absolute',
  transform: 'translateY(1rem)',
  transition: 'all 300ms ease-in-out',
  width: '7rem',

  svg: {
    fill: theme.colors.gray['600'],
    height: '2rem',
  },

  '&:hover': {
    height: '8rem',
    width: '8rem',
  },
}));

export default VideoPlay;
