import ArtistSection from './ArtistSection';
import ContentMain from 'primitives/Layout/ContentMain';
import DevicesSection from './DevicesSection';
import EventsSection from './EventsSection';
import Footer from 'components/Footer';
import Head from './Head';
import HeroSection from './HeroSection';
import hub, { E } from 'shared/utils/Hub';
import LiveSection from './LiveSection';
import NewInAppSection from './NewInAppSection';
import NewsSection from './NewsSection';
import PodcastSection from './PodcastSection';
import WelcomeWrapper from './primitives/WelcomeWrapper';
import { Component } from 'react';
import { navigate } from 'state/Routing/actions';
import { Redirect } from 'react-router';

export type Props = {
  countryCode: string;
  customRadioEnabled: boolean;
  isAnonymous: boolean;
  queryParams: {
    [key: string]: string | number | boolean;
  };
  search: string | undefined;
  selectedGenres: { [id: string]: boolean | undefined } | undefined;
  showWelcome: boolean;
};

type DispatchProps = {
  openLogin: () => void;
  openSignup: () => void;
  fetchGenrePreferences: () => Promise<void>;
};

const TILES_IN_ROW = 4;

export default class Welcome extends Component<Props & DispatchProps> {
  async componentDidMount() {
    if (!this.props.isAnonymous) {
      const { selectedGenres, fetchGenrePreferences } = this.props;
      if (!selectedGenres || !Object.keys(selectedGenres).length)
        await fetchGenrePreferences();
      // we access props directly here so that we can see updates caused by fetchGenrePreferences
      if (this.props.selectedGenres && !Object.keys(this.props.selectedGenres))
        navigate({ path: '/genre-options/' });
    }

    hub.on(E.CARD_CHANGE, () => {
      const { showLogin, showSignup } = this.props.queryParams;
      if (this.props.isAnonymous) {
        if (showLogin) this.props.openLogin();
        if (showSignup) this.props.openSignup();
      }
    });
  }

  render() {
    const {
      countryCode,
      customRadioEnabled,
      isAnonymous,
      search,
      showWelcome,
    } = this.props;

    if (__CLIENT__) {
      // redirect to /podcast for WW listeners.
      if (countryCode === 'WW') {
        return <Redirect to={`/podcast/${search}`} />;
      }

      // redirect to /for-you if feature flag is false or user is registered
      if (!showWelcome || !isAnonymous) {
        return <Redirect to={`/for-you/${search}`} />;
      }
    }

    return (
      <>
        <Head />
        <ContentMain>
          <WelcomeWrapper>
            <HeroSection />
            <LiveSection horizontalScroll singleRow tilesInRow={TILES_IN_ROW} />
            {customRadioEnabled ?
              <ArtistSection
                horizontalScroll
                singleRow
                tilesInRow={TILES_IN_ROW}
              />
            : null}
            <PodcastSection
              horizontalScroll
              singleRow
              tilesInRow={TILES_IN_ROW}
            />
            <DevicesSection />
            <NewsSection />
            <NewInAppSection />
            <EventsSection />
          </WelcomeWrapper>
        </ContentMain>
        <Footer adAboveNav={false} newFooter />
      </>
    );
  }
}
