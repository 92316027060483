import LiveSection from './LiveSection';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { flowRight } from 'lodash-es';
import { getAmpUrl } from 'state/Config/selectors';
import {
  getCurrentlySelectedCountry,
  getGenreFilter,
  getMarketFilter,
  getMarketOptions,
  getStationsForCurrentFilters,
} from 'state/Live/selectors';
import { getCurrentMarket } from 'state/Location/selectors';
import { getLiveRadioLink } from 'state/Links/selectors';
import { localize } from 'redux-i18n';
import { navigate } from 'state/Routing/actions';
import {
  setCountryOptions,
  setNewCountry,
  setNewMarket,
} from 'state/Live/actions';
import { State } from 'state/types';
import type { ConnectedComponentType } from 'utility';
import type { LiveSectionProps } from './types';

type ConnectedProps = {
  ampUrl: LiveSectionProps['ampUrl'];
  currentCountry: LiveSectionProps['currentCountry'];
  currentGenre: LiveSectionProps['currentGenre'];
  currentMarket: LiveSectionProps['currentMarket'];
  geoMarket: LiveSectionProps['geoMarket'];
  liveLink: LiveSectionProps['liveLink'];
  marketOptions: LiveSectionProps['marketOptions'];
  stations: LiveSectionProps['stations'];
};

const actions = {
  navigate,
  setCountry: setNewCountry,
  setCountryOptions,
  setMarket: setNewMarket,
};

export default flowRight(
  localize('translate'),
  connect(
    createStructuredSelector<State, ConnectedProps>({
      ampUrl: getAmpUrl,
      currentCountry: getCurrentlySelectedCountry,
      currentGenre: getGenreFilter,
      currentMarket: getMarketFilter,
      geoMarket: getCurrentMarket,
      liveLink: getLiveRadioLink,
      marketOptions: getMarketOptions,
      stations: getStationsForCurrentFilters,
    }),
    actions,
  ),
)(LiveSection) as ConnectedComponentType<
  typeof LiveSection,
  ConnectedProps & typeof actions & { translate: LiveSectionProps['translate'] }
>;
