import H4 from '../primitives/H4';
import Link from '../primitives/Link';
import LinkIcon from '../primitives/LinkIcon';
import LinksWrapper from '../primitives/LinksWrapper';
import LiveRadio from 'styles/icons/LiveRadio';
import Mic from 'styles/icons/Mic';
import NavRight from '../primitives/NavRight';
import News from 'styles/icons/News';
import PlaylistHome from 'styles/icons/PlaylistHome';
import theme from 'styles/themes/default';
import url from 'url';
import useTranslate from 'contexts/TranslateContext/useTranslate';

export type ConnectedProps = {
  internationalPlaylistRadioEnabled: boolean;
  onDemandEnabled: boolean;
};

type Props = ConnectedProps & {
  live: string;
  news: string;
  playlists: string;
  podcasts: string;
};

function Links({
  internationalPlaylistRadioEnabled,
  onDemandEnabled,
  ...links
}: Props) {
  const translate = useTranslate();

  const LINKS_MAP = [
    {
      color: theme.colors.red.primary,
      icon: LiveRadio,
      label: translate('Live Radio'),
      type: 'live',
    },
    {
      color: theme.colors.purple['300'],
      icon: Mic,
      label: translate('Podcasts'),
      type: 'podcasts',
    },
    {
      color: theme.colors.blue['500'],
      disabled: !(onDemandEnabled || internationalPlaylistRadioEnabled),
      icon: PlaylistHome,
      label: translate('Playlists'),
      type: 'playlists',
    },
    {
      color: theme.colors.orange['300'],
      icon: News,
      label: translate('News'),
      textColor: theme.colors.orange['500'],
      type: 'news',
    },
  ];

  return (
    <LinksWrapper>
      {LINKS_MAP.map(
        ({
          color,
          disabled = false,
          icon: Icon,
          label,
          textColor = color,
          type,
        }: (typeof LINKS_MAP)[number]) => {
          if (disabled) return null;

          const link =
            links[type as 'live' | 'podcasts' | 'news' | 'playlists'];

          return (
            <Link
              data-test={`link-${type}`}
              hoverColor={textColor}
              key={link}
              target={url.parse(link || '').host ? '_blank' : ''}
              to={link}
              underline={false}
            >
              <LinkIcon data-test={`link-icon-${type}`}>
                <Icon color={color} />
              </LinkIcon>
              <H4 data-test={`link-text-${type}`}>
                {label}
                <NavRight color={textColor} />
              </H4>
            </Link>
          );
        },
      )}
    </LinksWrapper>
  );
}

export default Links;
