type Props = {
  color: string;
  'data-test'?: string;
  height?: string | number;
  width?: string | number;
};

function PlaylistHome({ color, height = 24, width = 24, ...props }: Props) {
  const { 'data-test': dataTest } = props;

  return (
    <svg
      data-test={dataTest}
      fill={color}
      height={height}
      viewBox="0 0 24 24"
      width={width}
    >
      <path d="M16.3037 4H4.33037C3.5986 4 3 4.59867 3 5.33037C3 6.06207 3.5986 6.66074 4.33037 6.66074H16.3037C17.0354 6.66074 17.634 6.06207 17.634 5.33037C17.634 4.59867 17.0354 4 16.3037 4Z" />
      <path d="M16.3037 9.32147H4.33037C3.5986 9.32147 3 9.92014 3 10.6518C3 11.3835 3.5986 11.9822 4.33037 11.9822H16.3037C17.0354 11.9822 17.634 11.3835 17.634 10.6518C17.634 9.92014 17.0354 9.32147 16.3037 9.32147Z" />
      <path d="M4.33037 14.6429H10.9822C11.714 14.6429 12.3126 15.2416 12.3126 15.9733C12.3126 16.705 11.714 17.3037 10.9822 17.3037H4.33037C3.5986 17.3037 3 16.705 3 15.9733C3 15.2416 3.5986 14.6429 4.33037 14.6429Z" />
      <path d="M14.9733 21.4411V15.8137C14.9733 15.2948 15.532 14.9755 15.9844 15.2283L20.6806 18.0487C21.1065 18.3148 21.1065 18.94 20.6806 19.1928L15.9844 22.0132C15.532 22.2793 14.9733 21.96 14.9733 21.4411Z" />
    </svg>
  );
}

export default PlaylistHome;
