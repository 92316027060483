import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const CardsContainer = styled('div')(({ theme }) => ({
  float: 'left',
  marginBottom: '2rem',
  marginTop: '7.15rem',
  width: '30%',
  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    display: 'none',
  },
}));

export default CardsContainer;
